<template>
  <div>
    <div>
      <h5>
        Zoom Points      
      </h5>
    </div>

    <table
      v-if="zoomPoints.length"
      :class="['table kendo-table table-kendo mb-2 mt-2', { 'is-invalid': this.$validator.errors.has('ZoomPoints') }]"
    >
      <colgroup>
        <col width="35">
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>{{ $t('pan') }}</th>
          <th>{{ $t('tilt') }}</th>
          <th>{{ $t('zoom') }}</th>
          <th>{{ $t('options') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in zoomPoints"
          :key="`table-row-${ index }`"
        >
          <td>{{ index }}.</td>
          <td>
            <input
              v-model.number="item.pan"
              class="form-control form-control-sm"
              type="number"
              min="-170"
              max="170"
              step="1"
              @keyup="item.pan = clamp(item.pan.toFixed(2), -170, 170)"
              @click="changePtz({ pan: item.pan, tilt: item.tilt})"
            >
          </td>
          <td>
            <input
              v-model.number="item.tilt"
              class="form-control form-control-sm"
              type="number"
              min="-80"
              max="80"
              step="1"
              @keyup="item.tilt = clamp(item.tilt.toFixed(2), -80, 80)"
              @click="changePtz({ pan: item.pan, tilt: item.tilt })"
            >
          </td>
          <td>
            <input
              v-model.number="item.zoom"
              class="form-control form-control-sm"
              type="number"
              min="0"
              max="100"
              step="1"
              @keyup="item.zoom = item.zoom.toFixed(0)"
            >
          </td>
          <td>
            <button
              class="btn btn-sm btn-secondary btn-block"
              @click="removeItem(index)"
            >
              <font-awesome-icon
                class="mr-2 gray"
                icon="trash"
              />
              <span>{{ $t('remove') }}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else>
    <span
      v-show="errors.has('ZoomPoints')"
      class="badge badge-danger"
    >{{ errors.first('ZoomPoints') }}</span>
    <button
      class="btn btn-sm btn-primary float-right"
      @click="addItem()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
      />
      <span>{{ $t('add') }}</span>
    </button>
    <div
      class="form-group row mb-4 "
    >
      <label class="float-right ml-2">ZoomMap</label>
      <toggle-button
        v-model="showZoomMap"
        class="d-inline-block float-right ml-2"
        :width="40"
        :height="20"
        :font-size="12"
      />
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>
export default {
  name: 'ZoomConfiguration',
  props: {
    zoomPoints: {
      type: Array,
      required: true
    },
    pan: {
      type: Number,
      required: true
    },
    tilt: {
      type: Number,
      required: true
    }
  },
   data () {
    return {
      status: {
        currentPt: {
          pan: 0,
          tilt: 0
        }
      },
      statusCancelSource: null,
      showZoomMap: false
    }
  },
  watch: {
    showZoomMap () {
      if (this.showZoomMap) {
        this.calculateZoomMap();
      }
      else
      {
        this.$emit('zoomMapCalculated', null); //hides the zoomMap
      }
    }
  },
  mounted: function () {
  this.$watch('zoomPoints', function () {
    if(this.showZoomMap)
    {
      //console.log('a Ptz changed') 
      this.calculateZoomMap() //TODO: we need a timeout when you delete value and add new this is called too often
    }
  }, {deep:true})
},
  methods: {
    clamp (num, min, max) {
      return num <= min ? min : num >= max ? max : num;
    },
    addItem () {
        this.zoomPoints.push({ pan: this.pan, tilt: this.tilt, zoom: 0});
        this.update();
    },
    removeItem (removeIndex) {
      this.zoomPoints.splice(removeIndex, 1);
      this.update();
    },
    update () {
      this.$emit('update');
    },
    changePtz (position) {
      this.$emit('changePtz', position);
    },
    calculateZoomMap () {
      this.axios.post(`/ZoomMap/CalculateZoomPoints`, this.zoomPoints)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.$emit('zoomMapCalculated', response.data);
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>